import React, { useState, useEffect } from 'react';
import menu from '../icons/baseline_menu_white_36dp.webp';
import { HashLink as Link } from 'react-router-hash-link';
import Logo from '../media/drmlogo-white1.webp'
import Sidemenu from './sidemenu'
import { useDispatch, useSelector } from 'react-redux'

export default function Header(){
    
	const layout = useSelector((state) => state.layout.verticalMenu)
    let verticalMenu
        if (layout === true) {
            verticalMenu = <Sidemenu />
        }
        else {
            verticalMenu = ''
        }
        
    const dispatch = useDispatch()
    
    function HandleClick() {
        dispatch({ type: 'layout/Menu', payload: true})
        document.body.style.overflow = "hidden";
    }
    
    const [position, setPosition] = useState(window.pageYOffset)
    const [visible, setVisible] = useState(true) 
    
    
    useEffect(()=> {
        const handleScroll = () => {
           let moving = window.pageYOffset
     
           setVisible(position > moving);
           setPosition(moving)
        };
        window.addEventListener("scroll", handleScroll);
        return(() => {
           window.removeEventListener("scroll", handleScroll);
        })
    })

    const cls = visible ? "visible" : "hidden";
    
	const Profile = () => { 
        var profile = ''
		if(window.innerWidth <= 1100){
			profile = ( <div className = "dropdown cls" onClick={() => HandleClick()}><img  src={menu} alt='menu'/></div> )
		}
		else {
			profile = ( <div className="profile">
							<Link to="/#about" >About</Link>
                            <Link to="/#projects" >Projects</Link>
                            <Link to="/#contact" >Contact</Link>
						</div> )
		}
		return profile
	}
    
    return (
        <>
            {verticalMenu }
            <div className={`header ${cls}`}>
				<Link to="/#main" className="myself">
                    <img src={Logo} className="logo"/>Dajana Rosić-Mančeva</Link>
				<Profile/>
            </div>
        </>)
}
