import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import image3 from '../media/Screenshot_1.webp';
import image5 from '../media/Screenshot_5.webp';
import image6 from '../media/Screenshot_16.webp';
import image8 from '../media/Screenshot_8.webp';
import image9 from '../media/Screenshot_9.webp';
import image10 from '../media/Screenshot_13.webp';
import image11 from '../media/Screenshot_14.webp';
import image12 from '../media/Screenshot_15.webp';
import Calc from '../media/calc.png'
import Capstone from '../media/capstone.png'
import { AnimationOnScroll } from 'react-animation-on-scroll';
 

const Projects = ()=> {
	
	const media = [image8, image9, image10, image11, image12]
	var randomImage = media[Math.floor(Math.random()*media.length)]
	const [time, setTime] = useState(Date.now());
	
	useEffect(() => {
		const interval = setInterval(() => setTime(Date.now()), 4000);
		return () => {
			clearInterval(interval);
		};
        
	}, []);
   
	return (
            <div className='projects'>
               
                    <div className='card2'> 
                        
                            <AnimationOnScroll initiallyVisible={false} animateIn="animate__fadeIn" > 
                                <div className="photo_container">
                                    <img id="project_image" src={randomImage} className="screenshot" alt="" />
                                </div>
                            </AnimationOnScroll>
                       
                        
                            <div className='about'>
                               
                                 Developed by Oneshoo in conjunction with Llama for dYdX.<br/> Role: Front-End Developer. April-May 2022<br/> 
                                 ReactJS,
                                 Redux,
                                 Bootstrap,
                                 Vuexy
                            </div>
                    </div>
                    <div className='card2'>
                        <AnimationOnScroll initiallyVisible={false} animateIn="animate__fadeIn" >
                            <div className="photo_container">
                                <img src={image5} className="screenshot" alt="Miroslava Pajic Photography" />
                            </div>
                        </AnimationOnScroll>
                        
                        <div className='about'>A website for professional photographer. Ongoing.<br/>
                            HTML, CSS
                        </div>
                    </div>
                
                
                    <div className='card2'>
                        <AnimationOnScroll initiallyVisible={false} animateIn="animate__fadeIn" >
                            <div className="photo_container">
                                <img id="project_image" src={image3} className="screenshot" alt="Important People" />
                            </div>
                        </AnimationOnScroll>
                            <div className='about'>
                                Web application in which after login/registration you can save information about important people (and animals) in your life. Personal project.<br/>
                                HTML, CSS, JS, PHP, MySQL, JQuery, AJAX
                            </div>
                    </div>
                    <div className='card2'>              
                        <AnimationOnScroll initiallyVisible={false} animateIn="animate__fadeIn" >
                            <div className="photo_container">
                                <Link to='/calculator' target="_blank" rel='noopener noreferrer' title="Calculator"><img src={Calc}  className="screenshot" alt="Calculator" /></Link>
                            </div>
                        </AnimationOnScroll>
                       
                            <div className='about'>ReactJS Calculator. Assignment in React Basics course (part of Meta Front-End Specialization).
                            </div>
                      
                    </div>
               
                    <div className='card2'> 
                        <AnimationOnScroll initiallyVisible={false} animateIn="animate__fadeIn" > 
                            <div className="photo_container">
                                <img id="project_image" src={Capstone} className="screenshot" alt="" />
                            </div>
                        </AnimationOnScroll>
                   
                        <div className='about'>
                        Meta Front-End Capstone project. Restaurant website with an online booking a table functionality<br/><br/> 
                             ReactJS,
                             Node.js
                        </div>
                    </div>
                    <div className='card2'>
                        <AnimationOnScroll initiallyVisible={false} animateIn="animate__fadeIn" >
                            <div className="photo_container">
                                <img src={image6}  className="screenshot" alt="Dajana Rosic-Manceva portfolio" />
                            </div>
                        </AnimationOnScroll>
                        <div className='about'>
                        ReactJS, Redux, published with Netlify
                        </div>
                </div>
                 <div style={{display:'none'}}> {time}</div>
            </div>
           
	)
}

export default Projects
