import { createSlice } from '@reduxjs/toolkit'

export const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    verticalMenu: false
  },
  reducers: {
      Menu: (state, action) => {
                state.verticalMenu = action.payload
        },
    }
})

export const {
    Menu
} = layoutSlice.actions

export default layoutSlice.reducer
