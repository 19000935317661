import email from '../icons/baseline_mail_outline_white_24dp.webp';
import github from '../icons/GitHub-Mark-32px.webp';
import linkedin from '../icons/LI-In-Bug.webp';

const Contact = ()=>{
	return (
        <div className='footer' id="contact">
            <div className="headline">
                Thank you for visiting!<br/><br/>
                Feel free to get in touch!
            <br/> <br/>
            <div>
                <img src={email} className="icons email" onClick={() => window.location = 'mailto:dajanarosman@gmail.com'} title="dajanarosman@gmail" alt="dajanarosman@gmail"/>
                <img src={github} className="icons" onClick={()=> window.open("https://github.com/DajanaRM", "_blank")} title='https://github.com/DajanaRM' alt="https://github.com/DajanaRM"/>
                <img src={linkedin} className="icons" onClick={()=> window.open("https://www.linkedin.com/in/dajana-rosic-manceva/", "_blank")} title='https://www.linkedin.com/in/dajana-rosic-manceva/' alt="https://www.linkedin.com/in/dajana-rosic-manceva/"/>
            </div>
            </div>
            <div className='copywright' >&copy; 2022 - DAJANA ROSIC-MANCEVA</div>
        </div>
	)
}

export default Contact
