import React from "react";
import Image404 from "../media/image404.jpg";
import Header from './header'

const PageNotFound = () => {
    return (
        <div>
        <div className='bckg'>
            
            <Header />
           </div> 
           <a href="https://www.vecteezy.com/free-vector/404"  target="_blank"> <img src={Image404} className='image404'/></a>
        </div>
    );

}
 
export default PageNotFound;