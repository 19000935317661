import './assets/scss/style.scss'
import './assets/scss/calc.scss'
import Header from './components/header'
import Me from './components/me'
import About from './components/about'
import Projects from './components/projects'
import Contact from './components/contact'
import Certificates from './components/certificates'
import "animate.css/animate.min.css"
import usePageTracker from './hooks/usePageTracking'

function App() {
    
    window.addEventListener( "scroll",
      () => {
        document.body.style.setProperty(
          "--scroll",
          window.pageYOffset / (document.body.offsetHeight - window.innerHeight)
          
        )
      },
      
      false
    )
    
    usePageTracker()
    
    return (
        <div className="App">
            <Header />
            <Me />
            <About />
            <Projects />
            <Contact/>
        </div>
    )
}

export default App
