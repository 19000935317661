import { useDispatch } from 'react-redux'
import Icon from '@mdi/react';
import {  mdiClose } from '@mdi/js';
import { HashLink as Link } from 'react-router-hash-link';

const Sidemenu  = () => {
    const dispatch = useDispatch()
    
    function closeMenu() {
        dispatch({ type: 'layout/Menu', payload: false})
        document.body.style.overflow = "visible";
        }
 
    return(
        <div className= 'sidemenu'>
            <Icon path={mdiClose} title="Close" size={1.5} className="close-icon" onClick={() => closeMenu()}/>
            <div className="menu-items">
                <Link to="/#about" onClick={() => closeMenu()} className="menu-item">About</Link>
                <Link to="/#projects" onClick={() => closeMenu()} className="menu-item">Projects</Link>
                <Link to="/#contact" onClick={() => closeMenu()} className="menu-item">Contact</Link>
            </div>
            <div className='copywright' >&copy; 2022 - DAJANA ROSIC-MANCEVA </div>
        </div>
    )
}
export default Sidemenu