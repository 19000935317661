import React from 'react'
import Icon from '@mdi/react';
import { mdiMapMarkerOutline } from '@mdi/js';
import Logo from '../media/drmlogo2-white.webp'

const Me = () => {
	return (
		<div className="first" id="main">
                <img src={Logo} className="logo2" alt="profile"/>
            <div className="headline">
				Junior Front-end Developer (React)<br/><br/>Passionate about learning, self-motivated,<br/> forever curious!	
               		 <br/><br/>Dajana, 
			<Icon path={mdiMapMarkerOutline} title="Location" size={0.7}/> Sofia, BG
			</div>
		</div>
	)
}
export default Me
